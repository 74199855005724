import React, { useState } from "react";
import Part from "./Part";

const Sentence = ({ number, parts }) => {
  const [selections, setSelections] = useState([]);
  const [lastModifiedId, setLastModifiedId] = useState(null);
  const [lastModifiedChoicesIds, setLastModifiedChoicesIds] = useState([]);
  const sentence = [
    <Part key={`${number} + 000`} values={number} />,
    ...parts.map((e, i) => (
      <Part
        key={`${number} + ${i}`}
        selections={selections}
        setSelections={setSelections}
        lastModifiedId={lastModifiedId}
        setLastModifiedId={setLastModifiedId}
        lastModifiedChoicesIds={lastModifiedChoicesIds}
        setLastModifiedChoicesIds={setLastModifiedChoicesIds}
        values={e}
        lastPart={i === parts.length - 1}
      />
    )),
  ];
  return <div className="h-24 pl-4">{sentence}</div>;
};

export default Sentence;
