/*
Phrase
    - numéro
    - Ens. de Parties
        - Ens. de choix
            - value
            - id
            - type:
                (default = statique ou simple_change)
                visible_condition
                transformation_condition
                transformation_condition_or_simple_change
            - condition_id
*/
const datas = [
  {
    number: "1.",
    parts: [
      [
        { id: 1, value: "Le monde" },
        { id: 2, value: "Tout ce qui a lieu" },
        { id: 3, value: "La totalité des faits" },
      ],
      [{ id: 4, value: "est" }],
      [
        { id: 5, value: "tout ce qui a lieu" },
        { id: 6, value: "le monde" },
        { id: 7, value: "la totalité des faits" },
        { id: 8, value: "tous les faits" },
        { id: 9, value: "tous les états de chose subsistants" },
      ],
    ],
  },
  {
    number: "1.1",
    parts: [
      [
        { id: 10, value: "Le monde" },
        { id: 11, value: "Tout ce qui a lieu" },
        { id: 12, value: "La totalité des faits" },
      ],
      [{ id: 13, value: "est" }],
      [
        { id: 14, value: "la totalité des faits," },
        { id: 15, value: "le monde," },
        { id: 16, value: "tout ce qui a lieu," },
      ],
      [{ id: 17, value: "non" }],
      [
        {
          id: 18,
          value: "la totalité",
          type: "visible_condition",
          condition_id: [15, 16],
        },
      ],
      [{ id: 19, value: "des choses" }],
    ],
  },
  {
    number: "1.11",
    parts: [
      [
        { id: 20, value: "Le monde" },
        { id: 21, value: "Tout ce qui a lieu" },
        { id: 22, value: "La totalité des faits" },
      ],
      [{ id: 23, value: "est déterminé par" }],
      [
        { id: 24, value: "les faits," },

        {
          id: 25,
          value: "les états de choses subsistants,",
          type: "transformation_condition_or_simple_change",
          condition_id: 30,
        },
      ],
      [{ id: 26, value: "et par ceci qu'ils sont" }],
      [
        { id: 27, value: "tous les faits" },
        { id: 28, value: "le monde" },
        { id: 29, value: "la totalité des faits" },
        {
          id: 30,
          value: "tous les états de choses subsistants",
          type: "transformation_condition_or_simple_change",
          condition_id: 25,
        },
        { id: 31, value: "tout ce qui a lieu" },
      ],
    ],
  },
  {
    number: "1.12",
    parts: [
      [{ id: 32, value: "Car" }],
      [
        { id: 33, value: "la totalité des faits" },
        { id: 34, value: "le monde" },
        { id: 35, value: "tout ce qui a lieu" },
      ],
      [
        {
          id: 36,
          value: "détermine",
        },
      ],
      [
        {
          id: 36.1,
          value: "ce qui a lieu,",
        },
        {
          id: 36.11,
          value: "le fait,",
        },
        {
          id: 36.12,
          value: "l'état de chose subsistant,",
        },
      ],
      [
        {
          id: 36.2,
          value: "et aussi tout ce qui n'a pas lieu",
        },
      ],
    ],
  },
  {
    number: "1.13",
    parts: [
      [
        { id: 37, value: "Les faits" },
        { id: 38, value: "Les états de choses subsistants" },
      ],
      [{ id: 39, value: "dans l'espace logique sont" }],
      [
        { id: 40, value: "le monde" },
        { id: 41, value: "tout ce qui a lieu" },
        { id: 42, value: "la totalité des faits" },
      ],
    ],
  },
  {
    number: "1.2",
    parts: [
      [
        { id: 43, value: "Le monde" },
        { id: 44, value: "Tout ce qui a lieu" },
        { id: 45, value: "La totalité des faits" },
      ],
      [{ id: 46, value: "se décompose en" }],
      [
        { id: 47, value: "faits" },
        { id: 48, value: "états de choses subsistants" },
      ],
    ],
  },
  {
    number: "1.21",
    parts: [
      [
        {
          id: 49,
          value:
            "Quelque chose peut isolément avoir lieu ou ne pas avoir lieu, et tout le reste demeurer inchangé",
        },
      ],
    ],
  },
  {
    number: "2.",
    parts: [
      [
        { id: 50, value: "Ce qui a lieu," },
        { id: 51, value: "Le fait," },
        { id: 52, value: "La subsistance d'un état de choses," },
      ],
      [
        { id: 53, value: "le fait," },
        { id: 54, value: "l'état de chose subsistant," },
      ],
      [{ id: 55, value: "est" }],
      [
        { id: 56, value: "la subsistance d'états de chose" },
        { id: 57, value: "ce qui a lieu" },
        { id: 58, value: "le fait" },
        { id: 59, value: "la subsistance d'une connexion d'objets" },
      ],
    ],
  },
  {
    number: "2.01",
    parts: [
      [
        { id: 60, value: "L'état de choses" },
        { id: 62, value: "La connexion d'objets" },
      ],
      [{ id: 63, value: "est" }],
      [
        { id: 64, value: "une connexion d'objets" },
        { id: 65, value: "un état de choses" },
      ],
      [{ id: 66, value: "(entités," }],
      [
        { id: 67, value: "choses)" },
        {
          id: 68,
          value: "objets)",
          type: "transformation_condition",
          condition_id: 65,
        },
      ],
    ],
  },
];

export default datas;
