import datas from "./datas";
import Sentence from "./components/Sentence";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import NewsletterForm from "./components/NewsletterForm";

const url =
  "https://univ-amu.us1.list-manage.com/subscribe/post?u=146d5b4e6a994d2d4c6fb7636&amp;id=0f84a703c9";

const App = () => {
  const sentences = datas.map((e) => <Sentence key={e.number} {...e} />);
  return (
    <div>
      <div className="bg-gray-200">
        <h1
          className="text-5xl md:text-6xl lg:text-8xl text-center pt-10 mb-4 underline"
          style={{
            fontFamily: "IndustrialSans",
            textDecorationColor: "blue",
            textDecorationThickness: "5px",
            textUnderlineOffset: "5px",
          }}
        >
          TRACTATUS.DIGITAL
        </h1>
        <div
          className="text-center text-xl md:text-2xl"
          style={{ fontFamily: "Cormorant", fontWeight: 700 }}
        >
          Edition anniversaire du Traité logico-philosophique de Ludwig
          Wittgenstein
        </div>
        <div
          className="text-center text-xl md:text-2xl"
          style={{
            fontFamily: "Cormorant",
            fontWeight: 500,
            fontStyle: "italic",
          }}
        >
          améliorée par intelligence artificielle
        </div>
        <div
          className="text-center pb-12 text-xl md:text-2xl"
          style={{
            fontFamily: "Cormorant",
            fontWeight: 500,
            fontStyle: "italic",
          }}
        >
          alimentée par le crowdsourcing
        </div>
      </div>
      <div className="bg-gray-500 pb-10">
        <div className="max-w-5xl m-auto">
          <h2
            className="text-center text-5xl md:text-7xl pt-10 text-white uppercase"
            style={{
              fontFamily: "IndustrialSans",
              textShadow: "4px 4px gray",
            }}
          >
            Présentation
          </h2>
          <div className="rounded-xl md:rounded-3xl bg-white md:w-4/5 m-auto mt-2 border-4 border-yellow-300 shadow-2xl">
            <h3
              className="text-center text-2xl md:text-4xl pt-4"
              style={{
                fontFamily: "IndustrialSans",
              }}
            >
              Qu'est-ce que le Traité logico-philosophique ?
            </h3>
            <div className="w-36 h-1 bg-yellow-300 m-auto"></div>
            <p
              className="mt-6 w-5/6 m-auto text-xl md:text-2xl pb-8 text-justify"
              style={{ fontFamily: "Cormorant" }}
            >
              Le Traité logico-philosophique est le seul livre publié par Ludwig
              Wittgenstein de son vivant. <br />
              <br /> Paru en 1921, il est largement considéré comme un des plus
              importants ouvrages de la philosophie. <br />
              <br /> Selon l'auteur, le message du livre est le suivant: "tout
              ce qui proprement peut être dit peut être dit clairement, et sur
              ce dont on ne peut parler, il faut garder le silence."
            </p>
          </div>
          <div className="rounded-xl md:rounded-3xl bg-white md:w-4/5 m-auto mt-16 border-4 border-yellow-300 shadow-2xl">
            <h3
              className="text-center text-2xl md:text-4xl pt-4"
              style={{
                fontFamily: "IndustrialSans",
              }}
            >
              Qu'est-ce que le TRACTATUS.DIGITAL ?
            </h3>
            <div className="w-36 h-1 bg-yellow-300 m-auto"></div>
            <p
              className="mt-6 w-5/6 m-auto text-xl md:text-2xl pb-8 text-justify"
              style={{ fontFamily: "Cormorant" }}
            >
              TRACTATUS.DIGITAL est un projet d'édition multilingue, numérique
              et interactive du Traité logico-philosophique destinée à
              l'enseignement et à la recherche. <br />
              <br /> Notre but est de rendre l'ouvrage plus accessible et de
              permettre de nouvelles recherches sur sa construction. Le projet
              se propose d'employer une combinaison d'algorithmique et de
              crowdsourcing pour permettre de remplacer chaque expression du
              livre par une de ses définitions. La grammaire de la phrase sera
              ensuite adaptée pour rendre la lecture plus agréable. <br />
              <br /> Ce propotype emploie la traduction française de Gilles-Gaston Granger. Toute l'équipe remercie Madame Sylvie Granger pour nous avoir autorisé à l'utiliser.
            </p>
          </div>
        </div>
        <h2
          className="text-center text-5xl md:text-7xl mt-24 text-white uppercase"
          style={{
            fontFamily: "IndustrialSans",
            textShadow: "4px 4px gray",
          }}
        >
          Démonstration
        </h2>
        <div className="rounded-xl md:rounded-3xl bg-white md:w-4/5 max-w-6xl m-auto pt-6 border-4 border-yellow-300 shadow-2xl">
          {sentences}
        </div>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <NewsletterForm
              status={status}
              message={message}
              onValidated={(formData) => subscribe(formData)}
            />
          )}
        />
        <div
          className="text-white font-bold mt-10 flex flex-col md:flex-row items-center justify-center md:space-x-4 text-3xl"
          style={{
            fontFamily: "IndustrialSans",
            // textShadow: "4px 4px gray",
          }}
        >
          <a
            className="cursor-pointer hover:text-black"
            href="https://peers.press/e/99fc6f42-8bca-4734-a31f-9516f2d61ec5?id=99fc6f42-8bca-4734-a31f-9516f2d61ec5"
            target="_blank"
          >
            L'équipe
          </a>
          <a
            className="cursor-pointer hover:text-black"
            href="https://peers.press/e/99fc6f42-8bca-4734-a31f-9516f2d61ec5/blog"
            target="_blank"
          >
            Le blog
          </a>
          <a
            className="cursor-pointer hover:text-black"
            href="https://peers.press/b/50657561-8da5-41dd-9e8f-bb384a882b8e?id=50657561-8da5-41dd-9e8f-bb384a882b8e"
            target="_blank"
          >
            La feuille de route
          </a>
          <a
            className="cursor-pointer hover:text-black"
            href="https://peers.press/b/7704f7d4-2237-4f02-8371-061011a09bbd?id=7704f7d4-2237-4f02-8371-061011a09bbd"
            target="_blank"
          >
            Les autres projets TLP
          </a>
        </div>
      </div>
    </div>
  );
};

export default App;
