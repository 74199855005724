import { useEffect, useState } from "react";
import useComponentVisible from "../hooks/useComponentVisible";

const getType = (props) => {
  if (Array.isArray(props.values)) {
    const typeSearch = props.values.find(
      (e) => ![undefined, "static", "simple_change"].includes(e.type)
    );

    if (typeSearch) return typeSearch.type;
    if (props.values.length === 1) return "static";

    return "simple_change";
  }

  return null;
};

const initValue = ({ values }) =>
  Array.isArray(values) ? values[0].value : values + " -";

const setChoicesIds = ({ values }) =>
  Array.isArray(values) ? values.map((e) => e.id) : null;

const updateIdSelection = (selections, idSelected, choicesIds) => {
  if (selections.some((r) => choicesIds.includes(r))) {
    return [
      ...selections.filter(function (e) {
        return this.indexOf(e) < 0;
      }, choicesIds),
      idSelected,
    ];
  }
  return [...selections, idSelected];
};

const getConditionId = ({ values }) =>
  values.find((e) => e.condition_id).condition_id;

const getTransformationConditionValue = ({ values }) =>
  values.find((e) => e.condition_id).value;

const boldWhenModified = (valueSelected, values) =>
  values.findIndex((e) => e.value === valueSelected) > 0;

const checkComma = (value) => value.trim().substr(-1) === ",";

const cleanValue = (value, hasComma) =>
  hasComma ? value.trim().slice(0, -1) : value;

const Part = (props) => {
  const [value, setValue] = useState(initValue(props));
  const [previousValue, setPreviousValue] = useState(initValue(props));
  const [type] = useState(getType(props));
  const [hasComma] = useState(checkComma(initValue(props)));
  const [choicesIds] = useState(setChoicesIds(props));
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);

  useEffect(() => {
    if (type === "transformation_condition") {
      if (props.selections.includes(getConditionId(props))) {
        if (value !== getTransformationConditionValue(props)) {
          setValue(getTransformationConditionValue(props));
        }
      } else {
        setValue(props.values[0].value);
      }
    }

    if (type === "transformation_condition_or_simple_change") {
      if (
        !choicesIds.includes(props.lastModifiedId) &&
        props.lastModifiedChoicesIds.includes(getConditionId(props))
      ) {
        if (props.selections.includes(getConditionId(props))) {
          setValue(getTransformationConditionValue(props));
        } else {
          if (value !== previousValue) {
            setValue(previousValue);
          }
        }
      }
    }
  }, [choicesIds, previousValue, props, props.selections, type, value]);

  const choices =
    Array.isArray(props.values) &&
    props.values.map((e, i) => (
      <div
        key={i}
        className={`block ${
          e.value === value && "bg-red-100"
        } px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer text-lg`}
        role="menuitem"
        onClick={() => {
          setPreviousValue(
            type === "transformation_condition_or_simple_change" &&
              value === getTransformationConditionValue(props)
              ? e.value
              : value
          );
          setValue(e.value);
          props.setSelections(
            updateIdSelection(props.selections, e.id, choicesIds)
          );
          props.setLastModifiedId(e.id);
          props.setLastModifiedChoicesIds(choicesIds);
          setIsComponentVisible();
        }}
      >
        {cleanValue(e.value, hasComma)}
      </div>
    ));

  if (
    type === "visible_condition" &&
    !props.selections.some((r) => getConditionId(props).includes(r))
  ) {
    return null;
  }

  return (
    <span className="relative" ref={ref} style={{ fontFamily: "Cormorant" }}>
      <span
        className={`text-xl md:text-2xl ${
          (type === "simple_change" ||
            type === "transformation_condition_or_simple_change") &&
          "underline_text"
        } ${
          type !== "static" &&
          Array.isArray(props.values) &&
          boldWhenModified(value, props.values) &&
          "font-bold"
        }`}
        onClick={setIsComponentVisible}
      >
        {cleanValue(value, hasComma)}
      </span>
      {hasComma && <span>,</span>}
      {props.lastPart ? "." : <span>&nbsp;</span>}
      {(type === "simple_change" ||
        type === "transformation_condition_or_simple_change") &&
        isComponentVisible && (
          <div className="absolute sm:left-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {choices}
            </div>
          </div>
        )}
    </span>
  );
};

export default Part;
