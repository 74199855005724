import React from "react";

const NewsletterForm = ({ status, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <div>
      <h2
        className="text-center text-5xl md:text-6xl mt-12  text-white uppercase"
        style={{
          fontFamily: "IndustrialSans",
          textShadow: "4px 4px gray",
        }}
      >
        Newsletter
      </h2>
      <div className="rounded-xl bg-white md:w-4/5 px-4 py-10 m-auto mt-2 border-4 border-yellow-300 shadow-2xl flex flex-col text-center">
        <div className="sm:flex sm:justify-center">
          <input
            className="block text-xl sm:max-w-xs w-full px-4 py-3 appearance-none border border-gray-300 shadow-none bg-white rounded-md placeholder-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
            ref={(node) => (email = node)}
            type="email"
            placeholder="Your email"
            style={{ fontFamily: "Cormorant" }}
          />
          <button
            className="mt-4 relative sm:mt-0 sm:h-auto sm:ml-4 block w-full sm:w-auto border border-transparent px-6 py-3 text-xl font-semibold leading-snug bg-gray-900 text-white rounded-md shadow-md hover:bg-gray-800 focus:outline-none focus:bg-gray-800 transition ease-in-out duration-150 hover:bg-gray-600"
            onClick={submit}
            style={{ fontFamily: "Cormorant" }}
          >
            S'inscrire
          </button>
        </div>
        {status === "sending" && (
          <div
            className="text-lg md:text-xl mt-4 font-bold"
            style={{ color: "blue", fontFamily: "Cormorant" }}
          >
            Envoie...
          </div>
        )}
        {status === "error" && (
          <div
            className="text-lg md:text-xl mt-4 font-bold"
            style={{ color: "red", fontFamily: "Cormorant" }}
          >
            Erreur: Vérifiez l'écriture de votre adresse e-mail
          </div>
        )}
        {status === "success" && (
          <div
            className="text-lg md:text-xl mt-4 font-bold"
            style={{ color: "green", fontFamily: "Cormorant" }}
          >
            Email bien enregistré
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsletterForm;
